import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import WarehouseMap from "@/components/WarehouseMap";
// import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "carWarehouse",
  mixins: [mixins],
  components: {
    WarehouseMap
  },
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      isAdd: false,
      AMap: null,
      map: null,
      marker: null,
      parkType: "",
      address: null,
      type: 'LOOK',
      centerLngLat: [104.07318, 30.662818],
      // 默认当前坐标为成都市坐标
      columns: [{
        label: '仓库名称',
        prop: 'name'
      }, {
        label: '所属区域',
        prop: 'areaName'
      }, {
        label: '仓库管理员',
        prop: 'administratorName'
      }, {
        label: '仓库管理员电话',
        prop: 'administratorMobile'
      }, {
        label: '经度',
        prop: 'longitude'
      }, {
        label: '纬度',
        prop: 'latitude'
      }, {
        label: '定位',
        customRender: props => {
          return _createVNode(_resolveComponent("el-link"), {
            "type": "primary",
            "underline": false,
            "onClick": () => {
              this.addMarker(props.row);
            }
          }, {
            default: () => [_createTextVNode("\u4ED3\u5E93\u4F4D\u7F6E"), _createVNode("i", {
              "className": "el-icon-view el-icon--right"
            }, null)]
          });
        }
      }, {
        label: '详细地址',
        prop: 'address'
      }],
      topButtons: [{
        name: '新增',
        code: 'carWarehouse_add',
        type: 'primary',
        click: this.add,
        permissions: ['carWarehouse_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'carWarehouse_del',
        type: 'danger',
        click: this.Del,
        permissions: ['carWarehouse_del']
      }, {
        name: '编辑',
        code: 'carWarehouse_edit',
        click: this.Edit,
        permissions: ['carWarehouse_edit']
      }],
      searchItems: [{
        prop: 'name',
        label: '仓库名称'
      }, {
        type: 'cascader',
        prop: 'areaId',
        label: '区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }]
    };
  },
  methods: {
    addMarker(row) {
      console.log(row);
      this.parkType = '定位范围';
      this.address = {
        lng: row.longitude,
        lat: row.latitude,
        add: row.address,
        title: row.name,
        areaId: row.areaId,
        id: row.id
      };
      this.isAdd = true;
    },
    add() {
      this.$router.push('/carWarehouseAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/carWarehouseAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认要删除该条记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.carWarehouse.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};