import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a54d6bcc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "height": "400px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_layout_breadcrumb = _resolveComponent("layout-breadcrumb");
  const _component_table_page = _resolveComponent("table-page");
  const _component_WarehouseMap = _resolveComponent("WarehouseMap");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_layout_breadcrumb, {
    topMenuId: _ctx.topMenuId,
    onChangeTopToMenu: _ctx.changeTopToMenu,
    topMenuClick: _ctx.topMenuClick
  }, null, 8, ["topMenuId", "onChangeTopToMenu", "topMenuClick"]), _createVNode(_component_table_page, {
    fetchApi: _ctx.$api.business.carWarehouse.getList,
    columns: $data.columns,
    topButtons: $data.topButtons,
    linkButtons: $data.linkButtons,
    searchItems: $data.searchItems,
    selectionEnable: false,
    ref: "tablePage"
  }, null, 8, ["fetchApi", "columns", "topButtons", "linkButtons", "searchItems"]), _createVNode(_component_el_dialog, {
    title: "查看车辆仓库定位",
    "destroy-on-close": "",
    modelValue: $data.isAdd,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.isAdd = $event),
    width: "70%",
    center: ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_WarehouseMap, {
      addressData: $data.address,
      type: $data.type,
      parkType: $data.parkType
    }, null, 8, ["addressData", "type", "parkType"])])]),
    _: 1
  }, 8, ["modelValue"])]);
}